<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "VendorShowcase",
});

</script>

<template>
  <div class="bg-gray-100 dark:bg-primary-600 py-16 sm:py-8">
    <div class="relative mx-auto w-11/12 sm:px-6 lg:px-8">
      <NuxtImg format="webp" width="1440" height="300" class="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0" src="/img/ec-banner.png" alt="" />
      <div class="absolute inset-0 bg-gray-900/90 dark:bg-gray-900/80 mix-blend-multiply" />
      <div class="flex flex-row overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20 justify-between">
        <div class="relative mx-auto max-w-2xl lg:mx-0">
          <div class="flex flex-row gap-4 items-center">
            <NuxtImg format="webp" height="48" width="48" class="h-12 w-auto" src="/img/logo_icon_red.png" alt="" />
            <h1 class="text-xl font-bold text-white">Vendor Showcase</h1>
          </div>
          <figure>
            <blockquote class="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
              <p>"Featured vendors coming soon..."</p>
            </blockquote>
<!--            <figcaption class="mt-6 text-base text-white">-->
<!--              <div class="font-semibold">NichirinSword</div>-->
<!--              <div class="mt-1">EliteCreatures Owner</div>-->
<!--            </figcaption>-->
          </figure>
        </div>
<!--        <div class="relative max-w-2xl hidden lg:block">-->
<!--          <NuxtImg format="webp" height="256" width="256" src="/img/geode-logo.webp" class="h-64 w-auto" alt=""/>-->
<!--        </div>-->
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8 w-5/6 mx-auto h-[600px]">
    <div id="vendorProducts" class="w-full">
      <productCarousel endpoint="/products?is_paginated=false&limit=15&store_ids[]=74" class="my-6 max-w-full min-[1800px]:max-w-[1800px] m-auto">
        <template v-slot:title>
          <h2 class="text-2xl font-bold text-gray-900 dark:text-white">Vendor Top Products</h2>
        </template>
        <template v-slot:button>
          <NuxtLink href="/products" class="btn btn-outline">Browse all products</NuxtLink>
        </template>
      </productCarousel>
    </div>
  </div>
</template>